import { logout } from "@redux/actions/logout";
import { createSlice } from "@reduxjs/toolkit";
var initialState = [];
var syndicatedCollectionsSlice = createSlice({
    name: 'syndicatedCollections',
    initialState: initialState,
    reducers: {
        syndicatedCollectionPromotionToggled: function (state, action) {
            var collection = state.find(function (collection) { return collection.id === action.payload.id; });
            if (collection) {
                collection.is_promoted = !collection.is_promoted;
            }
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase("SYNDICATED_COLLECTIONS_DATA", function (state, action) {
            return action.data;
        })
            .addCase(logout, function () { return initialState; });
    }
});
export default syndicatedCollectionsSlice.reducer;
export var syndicatedCollectionPromotionToggled = syndicatedCollectionsSlice.actions.syndicatedCollectionPromotionToggled;
