import { preloadPhoto } from "shared/helpers/image";
export var addPrefetchedDataListener = function (startAppListening) {
    startAppListening({
        type: "FILTERS_CHECK_RESULTS_COUNT_PHOTOS_DATA",
        effect: function (action, _a) {
            var _b;
            var getState = _a.getState;
            var prefetchedData = getState().feedFilterRules.prefetchedData;
            if ((prefetchedData === null || prefetchedData === void 0 ? void 0 : prefetchedData.total_found) && ((_b = prefetchedData.data) === null || _b === void 0 ? void 0 : _b.length)) {
                prefetchedData.data.slice(0, 8).forEach(function (photo) { return preloadPhoto(photo.img); });
            }
        }
    });
};
